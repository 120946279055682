import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatUserData, getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import { ASSIGN_MISSION, GET_USER_ME, LIST_ALL_USERS, REGISTER_USER, UPDATE_USER } from './graphql/user';
import { IValues } from '../pages/_common/Users/NewUser';

export interface IAuthContextProps {
  userName: string;
  users: IUserProps[];
  registerUser: (userInput: IValues) => Promise<boolean>
  updateUser: (userInput: IValues, id: String) => Promise<boolean>
  assignMission: (userId: string, missionId: string) => Promise<boolean>;
  setUserName?(...args: unknown[]): unknown;
  setUserData?(...args: unknown[]): unknown;
  getUserMe: () => Promise<boolean>;
  getAllUsers: () => Promise<boolean>;
  userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
  const [userName, setUserName] = useState<string>(
    localStorage.getItem('argos_authUsername') || '',
  );
  const nav = useNavigate();
  const [userData, setUserData] = useState<Partial<IUserProps>>({});
  const [users, setAllUsers] = useState<IUserProps[]>([]);

  async function assignMission(userId: string, missionId: string) {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'assignMission',
            query: ASSIGN_MISSION,
            variables: {
              userId,
              missionId
            }
          }),
        })
      ).json();
      return true;
    } catch (err) {
      return false;
    }
  }

  async function updateUser(userInput: IValues, id: String) {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'UpdateUser',
            query: UPDATE_USER,
            variables: {
              userInput: {
                _id: id,
                email: userInput.emailAddress,
                password: userInput.confirmPassword || 'none',
                isAuth: userInput.rightsUser.includes('2'),
                phoneNumber: userInput.phoneNumber,
                name: userInput.lastName,
                firstname: userInput.firstName,
                rd: userInput.rightsUser.includes('1'),
                origin: "argos",
              }
            }
          }),
        })
      ).json();
      return true;
    } catch (err) {
      return false;
    }
  }

  async function registerUser(userInput: IValues) {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'RegisterUser',
            query: REGISTER_USER,
            variables: {
              email: userInput.emailAddress,
              password: userInput.confirmPassword || 'none',
              isAuth: userInput.rightsUser.includes('2'),
              phoneNumber: userInput.phoneNumber,
              name: userInput.lastName,
              firstname: userInput.firstName,
              rd: userInput.rightsUser.includes('1'),
              origin: "argos",
            }
          }),
        })
      ).json();
      return true;
    } catch (err) {
      return false;
    }
  }

  async function getUserMe() {
    const token = localStorage.getItem('token');
    if (!token) {
      localStorage.clear();
      nav('/auth-pages/login');
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'getUserMe',
            query: GET_USER_ME,
          }),
        })
      ).json();
      setUserName(response?.data?.getUserMe.firstname);
      setUserData(formatUserData(response?.data?.getUserMe));
      return true;
    } catch (err) {
      localStorage.clear();
      nav('/auth-pages/login');
      return false;
    }
  }

  async function getAllUsers() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'listAllUsers',
            query: LIST_ALL_USERS,
          }),
        })
      ).json();
      setAllUsers(response?.data?.listAllUsers.map((u: { _id: any }) => formatUserData(u)) || []);
      return true;
    } catch (err) {
      return false;
    }
  }
  useEffect(() => {
    localStorage.setItem('argos_authUsername', userName);
  }, [userName]);

  const value = useMemo(
    () => ({
      userName,
      setUserData,
      setUserName,
      getUserMe,
      getAllUsers,
      updateUser,
      registerUser,
      assignMission,
      userData,
      users,
    }),
    [userName, userData, users],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
  children: PropTypes.any,
};

export default AuthContext;
