export const LIST_ALL_REPORTINGS = `
    query listAllReportings {
        listAllReportings {
            _id
            rdData {
                name
                abs
                payed
                street
                tr
                bsInc
                bsComp
                real
                median_value
                total_bs
            }
            meteo
            spot
            comment
            date
            missionId
            createdBy
        }
    }
`;

export const CREATE_OR_UPDATE_REPORTING = `
    mutation createOrUpdateReporting($reportingInput: ReportingInput!) {
        createOrUpdateReporting(reportingInput: $reportingInput) {
            _id
            rdData {
                name
                abs
                payed
                street
                tr
                bsInc
                bsComp
                real
                median_value
                total_bs
            }
            meteo
            spot
            comment
            date
            missionId
            createdBy
        }
    }
`;
