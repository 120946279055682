export const CREATE_ASSOCIATION = `
    mutation CreateAssociation($associationName: String!) {
        createAssociation(associationName: $associationName) {
            _id
            name
        }
    }
`;

export const GET_ASSOCIATION = `
    query GetAssociation($getAssociationId: String!) {
        getAssociation(id: $getAssociationId) {
            _id
            name
            missions
            logoImage
            contacts {
                contactName
                email
                phoneNumber
                address
            }
        }
    }
`;

export const LIST_ASSOCIATIONS = `
    query ListAllAssociations {
        listAllAssociations {
            _id
            name
            missions
            logoImage
            contacts {
                contactName
                email
                phoneNumber
                address
            }
        }
    }
`;

export const DELETE_ASSOCIATION = `
    mutation {
        deleteAssociation($associationInput: AssociationInput!) {
            deleteAssociation(associationInput: $associationInput)
        }
    }
`;

export const LIST_MISSIONS = `
    query ListMissions {
        listMissions {
            _id
            missionId
            assoId
            startDate
            endDate
            formSchema
            objectif
            price_HTC
            price_TTC
            registrations {
                _id
                customerInfos {
                    firstname
                    name
                }
                status
                payementvalue
            }
            archived
        }
    }
`;
