import React, { createContext, FC, ReactNode, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ISpotProps {
  _id: string;
  name: string;
}

export interface ISpotContextProps {
  spots: ISpotProps[];
  addSpot: (name: string) => Promise<void>;
  getSpots: () => Promise<void>;
}

const SpotContext = createContext<ISpotContextProps>({} as ISpotContextProps);

interface ISpotContextProviderProps {
  children: ReactNode;
}

export const SpotContextProvider: FC<ISpotContextProviderProps> = ({ children }) => {
  const [spots, setSpots] = useState<ISpotProps[]>([]);
  const nav = useNavigate();

  // Fonction pour récupérer les spots depuis l'API GraphQL
  async function getSpots() {
    const query = `
      query getSpots {
        getSpots {
          _id
          name
        }
      }
    `;
    
    try {
      const response = await fetch(process.env.REACT_APP_API_URL || '', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
      });

      const result = await response.json();
      if (result.data) {
        setSpots(result.data.getSpots);
      } else {
        console.error('Erreur lors de la récupération des spots');
      }
    } catch (error) {
      console.error('Erreur réseau:', error);
      nav('/error');
    }
  }

  // Fonction pour ajouter un spot via une mutation GraphQL
  async function addSpot(name: string) {
    const mutation = `
      mutation addSpot($name: String!) {
        addSpot(name: $name)
      }
    `;
    
    try {
      const response = await fetch(process.env.REACT_APP_API_URL || '', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: mutation,
          variables: { name },
        }),
      });

      const result = await response.json();
      if (result.data) {
        setSpots((prevSpots) => [...prevSpots, result.data.addSpot]);
      } else {
        console.error('Erreur lors de l\'ajout du spot');
      }
    } catch (error) {
      console.error('Erreur réseau:', error);
    }
  }

  useEffect(() => {
    getSpots(); // Récupérer les spots au premier rendu
  }, []);

  const value = useMemo(
    () => ({
      spots,
      addSpot,
      getSpots,
    }),
    [spots],
  );

  return <SpotContext.Provider value={value}>{children}</SpotContext.Provider>;
};

export default SpotContext;
