import UserImage from '../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../assets/img/wanna/wanna2.png';
import UserImage2Webp from '../../assets/img/wanna/wanna2.webp';
import UserImage3 from '../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../assets/img/wanna/wanna3.webp';
import UserImage4 from '../../assets/img/wanna/wanna4.png';
import UserImage4Webp from '../../assets/img/wanna/wanna4.webp';
import UserImage5 from '../../assets/img/wanna/wanna5.png';
import UserImage5Webp from '../../assets/img/wanna/wanna5.webp';
import UserImage6 from '../../assets/img/wanna/wanna6.png';
import UserImage6Webp from '../../assets/img/wanna/wanna6.webp';
import UserImage7 from '../../assets/img/wanna/wanna7.png';
import UserImage7Webp from '../../assets/img/wanna/wanna7.webp';
import SERVICES, { IServiceProps } from './serviceDummyData';

import User7Landing from '../../assets/img/wanna/landing1.png';
import { TColor } from '../../type/color-type';

function capitalizestring(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const formatUserData = (userInput: any) => {
  const user: IUserProps = {
    id: userInput._id,
    username: `${userInput.firstname} ${userInput.name}`,
    firstName: userInput.firstname,
    password: userInput.password,
    fullName: userInput.name,
    isAdmin: userInput.isAdmin,
    email: userInput.email,
    missionId: userInput.missionId,
    isActive: userInput.isActive,
    src: userInput.pp || UserImage,
    srcSet: userInput.pp || UserImageWebp,
    isRd: userInput.rd,
    phoneNumber: userInput.phoneNumber,
    isReply: true,
    badges: userInput.badges,
    color: 'primary',
    assoId: userInput.assoId,
    uuid: userInput.uuid,
    lastLogin: userInput.lastLogin,
  };

  return user;
};
export interface IUserProps {
  id: string;
  username: string;
  password: string;
  firstName: string;
  fullName: string;
  isAdmin: boolean;
  email: string;
  missionId: string;
  isActive: boolean;
  src: string;
  srcSet?: string;
  isRd: boolean;
  phoneNumber: string;
  isReply: boolean;
  badges?: string[];
  color?: TColor | "link" | "brand" | "brand-two" | "storybook";
  assoId?: string;
  uuid: string;
  lastLogin: Date;
}

export const john: IUserProps = {
  id: "001",
  username: "",
  firstName: "",
  password: "",
  fullName: "",
  isAdmin: false,
  email: "",
  missionId: "",
  isActive: true,
  src: "",
  srcSet: "",
  isRd: false,
  phoneNumber: "",
  isReply: true,
  badges: [],
  color: "brand",
  assoId: "",
  uuid: "",
  lastLogin: new Date("2024-02-10T10:30:00Z"),
};

export const grace: IUserProps = {
  id: "002",
  username: "grace_w",
  firstName: "Grace",
  password: "123",
  fullName: "Grace Williams",
  isAdmin: true,
  email: "grace.w@example.com",
  missionId: "mission_002",
  isActive: true,
  src: "https://example.com/grace.jpg",
  srcSet: "https://example.com/grace@2x.jpg",
  isRd: true,
  phoneNumber: "+1234567891",
  isReply: false,
  badges: ["admin", "mentor"],
  color: "brand",
  assoId: "asso_002",
  uuid: "550e8400-e29b-41d4-a716-446655440002",
  lastLogin: new Date("2024-02-11T09:15:00Z"),
};

export const jane: IUserProps = {
  id: "003",
  username: "jane_d",
  firstName: "Jane",
  fullName: "Jane Doe",
  password: "123",
  isAdmin: false,
  email: "jane.doe@example.com",
  missionId: "mission_003",
  isActive: false,
  src: "https://example.com/jane.jpg",
  srcSet: "https://example.com/jane@2x.jpg",
  isRd: false,
  phoneNumber: "+1234567892",
  isReply: true,
  badges: ["newcomer"],
  color: "brand",
  assoId: "asso_003",
  uuid: "550e8400-e29b-41d4-a716-446655440003",
  lastLogin: new Date("2024-02-08T14:45:00Z"),
};

export const ryan: IUserProps = {
  id: "004",
  username: "ryan_s",
  firstName: "Ryan",
  fullName: "Ryan Smith",
  password: "123",
  isAdmin: true,
  email: "ryan.smith@example.com",
  missionId: "mission_004",
  isActive: true,
  src: "https://example.com/ryan.jpg",
  srcSet: "https://example.com/ryan@2x.jpg",
  isRd: true,
  phoneNumber: "+1234567893",
  isReply: false,
  badges: ["admin", "top-contributor"],
  color: "brand",
  assoId: "asso_004",
  uuid: "550e8400-e29b-41d4-a716-446655440004",
  lastLogin: new Date("2024-02-12T16:20:00Z"),
};

export const ella: IUserProps = {
  id: "005",
  username: "ella_m",
  firstName: "Ella",
  fullName: "Ella Martinez",
  password: "123",
  isAdmin: false,
  email: "ella.m@example.com",
  missionId: "mission_005",
  isActive: true,
  src: "https://example.com/ella.jpg",
  srcSet: "https://example.com/ella@2x.jpg",
  isRd: false,
  phoneNumber: "+1234567894",
  isReply: true,
  badges: ["supporter"],
  color: "brand",
  assoId: "asso_005",
  uuid: "550e8400-e29b-41d4-a716-446655440005",
  lastLogin: new Date("2024-02-09T12:10:00Z"),
};

export const chloe: IUserProps = {
  id: "006",
  username: "chloe_k",
  firstName: "Chloe",
  fullName: "Chloe Kim",
  password: "123",
  isAdmin: false,
  email: "chloe.k@example.com",
  missionId: "mission_006",
  isActive: true,
  src: "https://example.com/chloe.jpg",
  srcSet: "https://example.com/chloe@2x.jpg",
  isRd: false,
  phoneNumber: "+1234567895",
  isReply: false,
  badges: ["helper", "verified"],
  color: "brand",
  assoId: "asso_006",
  uuid: "550e8400-e29b-41d4-a716-446655440006",
  lastLogin: new Date("2024-02-07T18:05:00Z"),
};

export const sam: IUserProps = {
  id: "007",
  username: "sam_t",
  firstName: "Sam",
  fullName: "Sam Thompson",
  password: "123",
  isAdmin: true,
  email: "sam.t@example.com",
  missionId: "mission_007",
  isActive: false,
  src: "https://example.com/sam.jpg",
  srcSet: "https://example.com/sam@2x.jpg",
  isRd: true,
  phoneNumber: "+1234567896",
  isReply: true,
  badges: ["admin", "developer"],
  color: "brand",
  assoId: "asso_007",
  uuid: "550e8400-e29b-41d4-a716-446655440007",
  lastLogin: new Date("2024-02-05T08:55:00Z"),
};


const USERS: { [key: string]: IUserProps } = {
  JOHN: john,
  GRACE: grace,
  JANE: jane,
  RYAN: ryan,
  ELLA: ella,
  CHLOE: chloe,
  SAM: sam,
};

export function getUserDataWithUsername(username: string): IUserProps {
  // @ts-ignore
  return USERS[Object.keys(USERS)?.filter((f) => USERS[f].username.tostring() === username)];
}

export function getUserDataWithId(id?: string): IUserProps {
  // @ts-ignore
  return USERS[Object.keys(USERS)?.filter((f) => USERS[f].id === id)];
}

export default USERS;
