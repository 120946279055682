export const GET_USER_ME = `
query getUserMe{
	getUserMe{
        email,
        isAdmin,
        name,
        firstname,
        createdBy,
		missionId,
        isActive,
        registrationDate,
        lastLogin,
		uuid,
		rd,
        phoneNumber
		pp,
    }
}`;

export const LIST_ALL_USERS = `
query listAllUsers{
	listAllUsers{
        _id
		email,
		isAdmin,
		name,
		rd
		firstname,
		createdBy,
		isActive
		badges
		registrationDate
		missionId
		password
		lastLogin
		missionId
		uuid
        status
		pp
        phoneNumber
	}
}
`;

export const LOGIN = `
mutation authUser($email: String!, $password: String!, $origin: String!) {
	authUser (email: $email, password: $password, origin: $origin) {
		token
	}
}
`;

export const UPDATE_USER = `
	mutation UpdateUser($userInput: UserInput!) {
		updateUser(userInput: $userInput) {
			_id
			email,
			isAdmin,
			name,
			rd
			firstname,
			createdBy,
			isActive
			registrationDate
			missionId
			password
			lastLogin
			missionId
			uuid
			status
			pp
			phoneNumber
		}
	}
`;


export const REGISTER_USER = `
	mutation RegisterUser($email: String!, $password: String!, $isAuth: Boolean!, $phoneNumber: String!, $name: String!, $firstname: String!, $rd: Boolean!, $origin: String!) {
		registerUser(email: $email, password: $password, isAuth: $isAuth, phoneNumber: $phoneNumber, name: $name, firstname: $firstname, rd: $rd, origin: $origin) {
			token
		}
	}
`;

export const ASSIGN_MISSION = `
	mutation assignMission($userId: String, $missionId: String) {
		assignMission(userId: $userId, missionId: $missionId) {
			_id
		}
	}
`;
