import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import {
  CREATE_ASSOCIATION,
  DELETE_ASSOCIATION,
  GET_ASSOCIATION,
  LIST_ASSOCIATIONS,
  LIST_MISSIONS,
} from './graphql/associations';
import { IRegistration } from './registrationContext';

export type User = {
  email: string;
  isAdmin: boolean;
  name: string;
  firstname: string;
  createdBy: string;
  isActive: boolean;
  registrationDate: string;
  lastLogin: string;
  uuid: string;
  status: string;
  pp: string;
  phoneNumber: string;
};
export type IAssociation = {
  _id: string;
  name: string;
  missions: string[];
  logoImage: string;
  contacts: IContact[];
};

export type IMission = {
  _id: string;
  assoId: string;
  formSchema: string;
  startDate: string;
  endDate: string;
  objectif: number;
  price_TTC: number;
  missionId: string;
  price_HTC: number;
  registrations: [IRegistration];
};

export type IContact = {
  email: string;
  phoneNumber: string;
  address: string;
  contactName: string;
};
type CustomerInfos = {
  name: string;
  firstname: string;
  email: string;
  gender: string;
  phonenumber: string;
  address: string;
  birthdate: string;
  bankaccount: BankAccount;
};
type BankAccount = {
  iban: string;
  bic: string;
};

export interface IAssociationContextProps {
  setAssociationsData?(...args: unknown[]): unknown;
  associationsData: Partial<IAssociation[]>;
  missionsData: Partial<IMission[]> | undefined;
  associationData: Partial<IAssociation> | undefined;
  listAssociations: () => Promise<boolean>;
  listAllMissions: () => Promise<IMission[]>;
  getAssociation: (getAssociationId: string) => Promise<IAssociation>;
  createAssociation: (associationName: string) => Promise<boolean>;
  deleteAssociation: (associationInput: IAssociation) => Promise<boolean>;
}
const AssociationContext = createContext<IAssociationContextProps>({} as IAssociationContextProps);

interface IAssociationsContextProviderProps {
  children: ReactNode;
}
export const AssociationContextProvider: FC<IAssociationsContextProviderProps> = ({ children }) => {
  const [associationsData, setAssociationsData] = useState<Partial<IAssociation[]>>([]);
  const [missionsData, setMissionsData] = useState<Partial<IMission[]>>([]);
  const [associationData, setAssociationData] = useState<Partial<IAssociation>>();

  async function deleteAssociation(associationInput: IAssociation) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'deleteAssociation',
            query: DELETE_ASSOCIATION,
            variables: {
              associationInput,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {
      
      return false;
    }
  }
  async function createAssociation(associationName: string) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'CreateAssociation',
            query: CREATE_ASSOCIATION,
            variables: {
              associationName,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {
      
      return false;
    }
  }

  async function listAllMissions() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            operationName: 'ListMissions',
            query: LIST_MISSIONS,
            variables: {},
          }),
        })
      ).json();
      const newMissionsData = response?.data?.listMissions;
      setMissionsData(newMissionsData);
      return newMissionsData;
    } catch (err) {
      return false;
    }
  }

  async function listAssociations() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            operationName: 'ListAllAssociations',
            query: LIST_ASSOCIATIONS,
            variables: {},
          }),
        })
      ).json();
      setAssociationsData(response?.data?.listAllAssociations);
      return true;
    } catch (err) {
      return false;
    }
  }

  async function getAssociation(getAssociationId: string) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }

    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            operationName: 'GetAssociation',
            query: GET_ASSOCIATION,
            variables: {
              getAssociationId,
            },
          }),
        })
      ).json();
      if (response?.data?.getAssociation) {
        setAssociationData(response.data.getAssociation);
      }
      return response?.data?.getAssociation;
    } catch (err) {
      return false;
    }
  }

  const value = useMemo(
    () => ({
      associationsData,
      associationData,
      missionsData,
      setAssociationsData,
      listAssociations,
      listAllMissions,
      getAssociation,
      createAssociation,
      deleteAssociation,
    }),
    [associationsData],
  );
  return <AssociationContext.Provider value={value}>{children}</AssociationContext.Provider>;
};

export default AssociationContext;
