import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import {
  DELETE_REGISTRATION,
  DEPOSE_REGISTRATIONS,
  EXPORT_REGISTRATION,
  GET_ALL_REGISTRATIONS,
  GET_REGISTRATION,
  RESEND_EMAIL_REGISTRATION,
  RESEND_SMS_REGISTRATION,
  UPDATE_REGISTRATION,
} from './graphql/registrations';

export type User = {
  email: string;
  isAdmin: boolean;
  name: string;
  firstname: string;
  createdBy: string;
  isActive: boolean;
  registrationDate: string;
  lastLogin: string;
  uuid: string;
  status: string;
  pp: string;
  phoneNumber: string;
};
export type IRegistration = {
  _id: string;
  createdBy: string;
  customerInfos: CustomerInfos;
  payementvalue: number;
  signature: string;
  active: boolean;
  createdAt: string;
  assoId: string;
  missionId: string;
  newsletter: boolean;
  rd: User;
  incomplete: boolean;
  commentary: string;
  status: string;
};

type CustomerInfos = {
  name: string;
  firstname: string;
  email: string;
  gender: string;
  phonenumber: string;
  address: string;
  birthdate: string;
  bankaccount: BankAccount;
};
type BankAccount = {
  iban: string;
  bic: string;
};

export interface IRegistrationContextProps {
  setRegistrationsData?(...args: unknown[]): unknown;
  registrationsData: Partial<IRegistration[]>;
  ExportRegistration: (searchOptions: SearchOptions) => Promise<boolean>;
  DeposeRegistrations: (minDate: string, prelevementDate: string) => Promise<boolean>;
  getAllRegistrations: (missionId?: string) => Promise<boolean>;
  updateRegistration: (registrationInput: IRegistration) => Promise<boolean>;
  deleteRegistration: (registrationInput: IRegistration) => Promise<boolean>;
  reSendEmail: (registrationInput: IRegistration) => Promise<boolean>;
  reSendSMS: (registrationInput: IRegistration) => Promise<boolean>;
  getRegistration: (regId: string) => Promise<IRegistration>;
}
const RegistrationContext = createContext<IRegistrationContextProps>(
  {} as IRegistrationContextProps,
);

interface IRegistrationContextProviderProps {
  children: ReactNode;
}
interface SearchOptions {
  status?: [string];
  missionId?: string;
  assoId?: string;
}

// Helper function to handle circular references
const sanitizeData = (data: Partial<IRegistration[]>) => {
  return data.map(item => ({
    ...item,
    customerInfos: {
      ...item.customerInfos,
      bankaccount: {
        ...item.customerInfos?.bankaccount,
      },
    },
  }));
};

// Safe JSON stringification to avoid circular reference errors
const safeStringify = (obj: any) => {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    console.error('Failed to stringify object:', e);
    return null;
  }
};

export const RegistrationContextProvider: FC<IRegistrationContextProviderProps> = ({
  children,
}) => {
  const [registrationsData, setRegistrationsData] = useState<Partial<IRegistration[]>>([]);

  async function DeposeRegistrations(minDate: string, prelevementDate: string) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'generateXLSX',
            query: DEPOSE_REGISTRATIONS,
            variables: {
              minDate,
              prelevementDate,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {

      return false;
    }
  }

  async function ExportRegistration(searchOptions: SearchOptions) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URL || '', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: token || '',
        },
        body: JSON.stringify({
          operationName: 'Query',
          query: EXPORT_REGISTRATION,
          variables: {
            searchOptions,
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch CSV data');
      }

      const csvString = await response.text(); // Get CSV as a string

      // Create a Blob from the CSV string
      const blob = new Blob([csvString], { type: 'text/csv' });

      // Create a temporary download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${moment(new Date()).format('DD-MM-YYYY')}-${searchOptions.assoId}-${searchOptions.missionId}-registrations.csv`; // Filename for the download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Release the Blob URL
      window.URL.revokeObjectURL(url);
      return true;
    } catch (error) {
      console.error('Download error:', error);
      return false;
    }
  }

  async function deleteRegistration(registrationInput: IRegistration) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'deleteRegistration',
            query: DELETE_REGISTRATION,
            variables: {
              registrationInput,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {

      return false;
    }
  }

  async function reSendSMS(registrationInput: IRegistration) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'reSendSMS',
            query: RESEND_SMS_REGISTRATION,
            variables: {
              registrationInput,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {

      return false;
    }
  }

  async function reSendEmail(registrationInput: IRegistration) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'reSendEmail',
            query: RESEND_EMAIL_REGISTRATION,
            variables: {
              registrationInput,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {

      return false;
    }
  }

  async function updateRegistration(registrationInput: IRegistration) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token || '',
          },
          body: JSON.stringify({
            operationName: 'updateRegistration',
            query: UPDATE_REGISTRATION,
            variables: {
              registrationInput,
            },
          }),
        })
      ).json();
      return true;
    } catch (err) {

      return false;
    }
  }

  async function getAllRegistrations(missionId?: string, status?: string[]) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            operationName: 'listAllRegistrations',
            query: GET_ALL_REGISTRATIONS,
            variables: {
              searchOptions: {
                status,
                missionId,
              },
            },
          }),
        })
      ).json();
      const sanitizedData = sanitizeData(response?.data?.listAllRegistrations || []);
      setRegistrationsData(sanitizedData);
      return true;
    } catch (err) {
      return false;
    }
  }

  async function getRegistration(regId: string) {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const response = await (
        await fetch(process.env.REACT_APP_API_URL || '', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            operationName: 'getRegistration',
            query: GET_REGISTRATION,
            variables: {
              regId
            },
          }),
        })
      ).json();

      return response?.data?.getRegistration;
    } catch (err) {
      return false;
    }
  }

  const value = useMemo(
    () => ({
      registrationsData,
      setRegistrationsData,
      ExportRegistration,
      getAllRegistrations,
      updateRegistration,
      deleteRegistration,
      reSendSMS,
      reSendEmail,
      DeposeRegistrations,
      getRegistration
    }),
    [registrationsData],
  );

  return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
};

RegistrationContextProvider.propTypes = {
  children: PropTypes.any,
};

export default RegistrationContext;
